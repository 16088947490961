import { EnderecoModel } from './../models/endereco.modal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DetalheEnderecoViewModel } from '../viewModel/endereco/detalheEnderecoViewModel';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(private http: HttpClient) { }
  public NomeServico = '/endereco';

  cadatrar(data: EnderecoModel): Observable<any> {
    return this.http.post(`${environment.urlBase}${this.NomeServico}`, data);
  }

  atualizar(data: EnderecoModel, Id: string): Observable<any> {
    return this.http.put(`${environment.urlBase}${this.NomeServico}/${Id}`, data);
  }

  deletar(Id: string): Observable<any> {
    return this.http.delete(`${environment.urlBase}${this.NomeServico}/${Id}`);
  }

  buscarPorUsuario(id: string): Observable<EnderecoModel> {
    return this.http.get<EnderecoModel>(`${environment.urlBase}${this.NomeServico}/buscarEnderecoPorUsuario/${id}`);
  }

  buscarPorId(Id: string): Observable<any> {
    return this.http.get<EnderecoModel>(`${environment.urlBase}${this.NomeServico}/BuscarEnderecoPorId/${Id}`);
  }
}
