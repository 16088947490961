import { alertController } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { LinguagemUltil } from 'src/app/shared/Ultils/linguagem-ultil';
import { LinguagemModel } from './../models/linguagem.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinguagemService {
  constructor(private http: HttpClient) { }

  public NomeServico = '/linguagem';
  cadatrar(data: LinguagemModel) {
    return this.http.post(`${environment.urlBase}${this.NomeServico}`, data);
  }

  atualizar(data: LinguagemModel, id: string) {
    return this.http.put(`${environment.urlBase}${this.NomeServico}/${id}`, data);
  }

  deletar(id: string) {
    return this.http.delete(`${environment.urlBase}${this.NomeServico}/${id}`);
  }

  buscarTodas(): Observable<LinguagemModel[]> {
    return this.http.get<LinguagemModel[]>(`${environment.urlBase}${this.NomeServico}/buscarTodas`);
  }

  buscarPorId(id: string) {
    return this.http.get<LinguagemModel>(`${environment.urlBase}${this.NomeServico}/${id}`);
  }

  buscarPorPais(pais: string): Observable<LinguagemModel> {
    return this.http.get<LinguagemModel>(`${environment.urlBase}${this.NomeServico}/buscarPorPais/${pais}`);
  }

  async alertaSelecionarLinguagem(translate: TranslateService, checbuttonsz, linguagens) {

    const header = translate.instant('ALERTAS.SELECIONAR_LINGUAGEM.HEADER');
    const message = translate.instant('ALERTAS.SELECIONAR_LINGUAGEM.MESSAGE');
    const botaoConfirmacao = translate.instant('ALERTAS.BOTAO.CONFIRMAR');
    const botaoCancelar = translate.instant('ALERTAS.BOTAO.CANCELAR');
    const alert = await alertController.create({
      header: header.toString(),
      message: message.toString(),
      inputs: checbuttonsz,

      buttons: [
        {
          text: botaoCancelar.toString(),
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: botaoConfirmacao.toString(),
          id: 'confirm-button',
          handler: (c) => {
            this.setAbreviacao(c, linguagens, translate);
          },
        },
      ],
    });
    await alert.present();
  }

  private setAbreviacao(idLinguagem, linguagens, translate: TranslateService,) {
    let linguagem = linguagens.find(x => x.id === idLinguagem);
    LinguagemUltil.setarLinguagem(linguagem);
    translate.setDefaultLang(linguagem.abreviacao);
  }

}
