import { ModalIdiomasComponent } from './../pages/menu/modal-idiomas/modal-idiomas.component';
import { NavBarComponent } from './../pages/menu/nav-bar/nav-bar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SliderAnuncioComponent } from './../pages/anuncio/slider-anuncio/slider-anuncio.component';
import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule } from '@ionic/angular';
import { BrMaskerModule } from 'br-mask';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { LoadingInterceptor } from '../Loading.Interceptor.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ReplacePipe } from './replace.pipe';
import { ModalTermosComponent } from '../pages/perfil/modal-termos/modal-termos.component';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/il8n/', '.json');
}


@NgModule({
  declarations: [
    SliderAnuncioComponent, LoadingComponent, NavBarComponent, ReplacePipe, ModalIdiomasComponent, ModalTermosComponent
  ],
  exports: [
    ReactiveFormsModule,
    TranslateModule,
    BrMaskerModule,
    SliderAnuncioComponent,
    SweetAlert2Module,
    GooglePlaceModule,
    LoadingComponent,
    NavBarComponent,
    ReplacePipe,
    ModalIdiomasComponent,
    ModalTermosComponent
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    GooglePlaceModule,
    IonicModule.forRoot(),
    SweetAlert2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrMaskerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
