import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from './services/loading.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { of, throwError } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (request.url.includes('/api/account/validarEmail/')) {
      return next.handle(request);
    } else {
      if (this.activeRequests === 0) {
        this.loadingService.show();
      }
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      // catchError((err) => fromFetch(server2)),

      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
          // this.router.navigate(['/error', window.location.href]);
        } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          this.router.navigate(['/error'], { queryParams: { NumeroErro: error.status } });
        }
        if (this.activeRequests === 0) {
          console.log(error);
          this.loadingService.hide();
        }
        return throwError(errorMsg);
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loadingService.hide();
        }
      })
    );
  }
}
