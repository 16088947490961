import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OneSignalTagIdUsuario } from 'src/app/oneSignal/onseSignalTagIdUsuario';
import { GerenciamentoLinguagem } from 'src/app/shared/Ultils/gerenciar-linguagem';
import { LinguagemUltil } from 'src/app/shared/Ultils/linguagem-ultil';
import { Security } from 'src/app/shared/Ultils/security-ultil';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  public role: string = '';
  public nome: string = '';
  public email: string = '';
  public carregamento = false;
  
  constructor(
    private router: Router,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    public translate: TranslateService,
    private oneSignalTagIdUsuario: OneSignalTagIdUsuario,
    private gerenciamentoLinguagem: GerenciamentoLinguagem,

  ) {
    console.log('this.gerenciamentoLinguagem.buscarLinguagemSetadaDinamica()',this.gerenciamentoLinguagem.buscarLinguagemSetadaDinamica())

   }

  ngOnInit() {
    this.carregamento = false;
    this.setUser();
    this.menuCtrl.close();
    this.carregamento = true;
    this.gerenciamentoLinguagem.buscarLinguagemDinamicaObservable().subscribe((d)=>{
      this.carregarMenu();
    });
    this.carregarMenu();
  }

  carregarMenu(){
    if (LinguagemUltil.buscarLinguagemIngles()) {
      this.translate.setDefaultLang(LinguagemUltil.buscarLinguagemIngles());
    } else {
      this.translate.setDefaultLang(
        LinguagemUltil.retornaLinguagemParaTraducao()
      );
    }
  }

  goToPage(page: string) {
    this.menuCtrl.close();
    this.navCtrl.navigateRoot(page);
  }

  ionViewWillEnter(): void {
    Security.setarEstadoUsuario();
    this.setUser();
  }

  logout() {
    Security.clear();
    this.oneSignalTagIdUsuario.excluirTags();
    this.router.navigate(['/home']);
  }

  setUser(){
    Security.buscarEstadoUsuario().subscribe(data => {
      this.email = data.email;
      this.nome = data.nome;
      this.role = data.role;
    });
  }

}
