import { BehaviorSubject } from "rxjs";

export class LinguagemUltil {
  public linguagem = new BehaviorSubject('');


  public static setarLinguagem(linguageAbreviacao: any) {
    const data = JSON.stringify(linguageAbreviacao);
    localStorage.setItem('linguagem', data);
  }

  public static setarTraducaoLinguagemIngles(linguageAbreviacao: any) {
    const data = JSON.stringify(linguageAbreviacao);
    localStorage.setItem('linguagemIngles', data);
  }

  public static removerTraducaoIngles() {
    localStorage.removeItem('linguagemIngles');
  }

  public static buscarLinguagemIngles() {
    const data = localStorage.getItem('linguagemIngles');
    if (data !== undefined || data !== null) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }


  public static buscarLinguagem() {
    const data = localStorage.getItem('linguagem');
    if (data !== undefined || data !== null) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public static retornaLinguagemParaTraducao(): string {
    let linguagem ;
    if(!this.buscarLinguagemIngles()) {
      linguagem = LinguagemUltil.buscarLinguagem()?.abreviacao
        ? LinguagemUltil.buscarLinguagem().abreviacao
        : 'pt-br';
    } else {
      linguagem = this.buscarLinguagemIngles();
    }

    return linguagem;
  }
}
