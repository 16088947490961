import { Injectable } from "@angular/core";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { Platform } from "@ionic/angular";
import { Security } from "../shared/Ultils/security-ultil";

// export interface Tags {
//     id_usuario: string;
// }

@Injectable({
    providedIn: 'root'
})

export class OneSignalTagIdUsuario {
    private tagIdUsuario: any = ['id_usuario'];
    private tagIdCidadePerfil: any = ['id_cidade_perfil'];
    private tagIdEstadoPerfil: any = ['id_estado_perfil'];
    private tagIdPaisPerfil: any = ['id_pais_perfil'];
    private tagPerfil: any = ['perfil'];

    private cordova: boolean = false;

    constructor(private platform: Platform, private oneSignal: OneSignal) {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                this.cordova = true;
            }
        });
        console.log('', Security.buscarUsuario()?.id_Cidade + '-' + Security.buscarUsuario()?.role)
        console.log('', Security.buscarUsuario()?.id_Estado + '-' + Security.buscarUsuario()?.role)
        console.log('', Security.buscarUsuario()?.id_Pais + '-' + Security.buscarUsuario()?.role)

    }

    public salvarTagOneSinal() {
        if (this.cordova) {
            this.oneSignal.sendTag('id_usuario', Security.buscarUsuario().id);
            this.oneSignal.sendTag('id_cidade_perfil', Security.buscarUsuario()?.id_Cidade + '-' + Security.buscarUsuario()?.role);
            this.oneSignal.sendTag('id_estado_perfil', Security.buscarUsuario()?.id_Estado + '-' + Security.buscarUsuario()?.role);
            this.oneSignal.sendTag('id_pais_perfil', Security.buscarUsuario()?.id_Pais + '-' + Security.buscarUsuario()?.role);
            this.oneSignal.sendTag('perfil', Security.buscarUsuario()?.role);
        }
    }

    public excluirTags() {
        if (this.cordova) {
            this.oneSignal.getTags();
            this.oneSignal.deleteTags(this.tagIdUsuario);
            this.oneSignal.deleteTags(this.tagIdCidadePerfil);
            this.oneSignal.deleteTags(this.tagIdEstadoPerfil);
            this.oneSignal.deleteTags(this.tagIdPaisPerfil);
            this.oneSignal.deleteTags(this.tagPerfil);
        }
        localStorage.removeItem('id_usuario');
    }
}
