import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: "root",
})

export class TratamentoMensagemApi {
    constructor(private toastCtrl: ToastController) { }

    //Erro de requisição
    async mensagemErroRequisicaoApi(data: any, translate: TranslateService, mensagem?: string) {
        let msg = '';

        if (data.status == 0) {
            msg = translate.instant('MENSSAGENS_VALIDACAO.MENSSAGEM_0');
        } else if (data.status == 404) {
            msg = translate.instant('MENSSAGENS_VALIDACAO.MENSSAGEM_404');
        } else if (data.status == 500 || data.status == 400) {
            msg = translate.instant('MENSSAGENS_VALIDACAO.MENSSAGEM_400');
        } else if (mensagem) {
            msg = mensagem
        }
        else {
            msg = translate.instant('MENSSAGENS_VALIDACAO.MENSSAGEM_RETORNO_ERRO');
        }
        this.alert(msg, 'danger');
    }

    //Mensagem de requisição
    async menssagemRetornoRequisicao(status: number, translate: TranslateService) {
        if (status == 200) {
            let msg = translate.instant('MENSSAGENS_VALIDACAO.OPERACAO_SUCESSO');
            this.alert(msg, 'success');
        }

        if (status == 500) {
            let msg = translate.instant('MENSSAGENS_VALIDACAO.OPERACAO_AVISO');
            this.alert(msg, 'warning');
        }
    }
    
    //mostrar mensagem de erro de login
    async mostrarMensagemLogin(data: any, translate: TranslateService, mensagem?: string) {
        let msg = '';
        msg = mensagem
        this.alert(msg, 'warning');
    }


    async alert(msg: string, color: string) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 3000,
            position: 'bottom',
            color: color
        });
        toast.present();
    }
}
