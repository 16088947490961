import { CategoriaModel } from './../models/categoria.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private http: HttpClient) { }

  cadatrar(data:CategoriaModel){
    return this.http.post(`${environment.urlBase}/categoria`, data);
  }

  atualizar(data:CategoriaModel, Id: string){
    return this.http.put(`${environment.urlBase}/categoria/${Id}`, data);
  }

  deletar(Id: string){
    return this.http.delete(`${environment.urlBase}/categoria/${Id}`);
  }

  buscarPorLinguagem(id: string){
    return this.http.get<CategoriaModel[]>(`${environment.urlBase}/categoria/buscarPorLinguagemApp/${id}`);
  }

  buscarTodos(){
    return this.http.get<CategoriaModel[]>(`${environment.urlBase}/categoria/buscarTodos`);
  }

  buscarCategoriaPorProfissional(Id: string): Observable<CategoriaModel>{
    return this.http.get<CategoriaModel>(`${environment.urlBase}/categoria/${Id}`);
  }

  buscarPorId(Id: string){
    return this.http.get(`${environment.urlBase}/categoria/${Id}`);
  }

  // buscarPalavrasChaves(idCategoria: string): Observable<any> {
  //   return this.http.get<CategoriaModel[]>(`${environment.urlBase}/categoria/palavrasChaves/${idCategoria}`);
  // }
}
