import { HomePageModule } from './pages/home/home.module';
import { Interceptor } from './app.interceptor.module';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {IonicInputMaskModule} from '@thiagoprz/ionic-input-mask';
import { MenuPage } from './pages/menu/menu/menu.page';
import { IonicSelectableModule } from 'ionic-selectable';
import { ErrorComponent } from './pages/erro/error/error.component';
import { OneSignalTagIdUsuario } from './oneSignal/onseSignalTagIdUsuario';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AuthGuard } from './guards/auth.guard';


@NgModule({
  declarations: [AppComponent, MenuPage, ErrorComponent
  ],
  exports: [IonicInputMaskModule ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicInputMaskModule,
    IonicSelectableModule,
    SharedModule,
    HomePageModule,
    Interceptor,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),

  ],
  providers:
  [AuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    OneSignal,
    OneSignalTagIdUsuario,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
