import { ErrorComponent } from './pages/erro/error/error.component';
import { Component } from '@angular/core';
/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuPage } from './pages/menu/menu/menu.page';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'cadastrar-perfil',
    loadChildren: () => import('./pages/perfil/cadastrar-perfil/cadastrar-perfil.module').then(m => m.CadastrarPerfilPageModule)
  },
  {
    path: 'cadastrar-endereco-perfil',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/perfil/cadastrar-endereco-perfil/cadastrar-endereco-perfil.module').then(m => m.CadastrarEnderecoPerfilPageModule)
  },
  {
    path: '',
    component: MenuPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', pathMatch: "full",
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
      },
      {
        path: 'categoria',
        loadChildren: () => import('./pages/categoria/categoria.module').then(m => m.CategoriaPageModule)
      },
      {
        path: 'subcategoria/:categoriaId',
        loadChildren: () => import('./pages/subcategoria/subcategoria.module').then(m => m.SubcategoriaPageModule)
      },
      {
        path: 'subcategoria/:nomeCategoria/:categoriaId',
        loadChildren: () => import('./pages/subcategoria/subcategoria.module').then(m => m.SubcategoriaPageModule)
      },
      {
        path: 'cadastrar-endereco',
        loadChildren: () => import('./pages/endereco/cadastrar-endereco/cadastrar-endereco.module').then(m => m.CadastrarEnderecoPageModule)
      },
      {
        path: 'atualizar-endereco/:id',
        loadChildren: () => import('./pages/endereco/atualizar-endereco/atualizar-endereco.module').then(m => m.AtualizarEnderecoPageModule)
      },
      {
        path: 'detalhe-endereco',
        loadChildren: () => import('./pages/endereco/detalhe-endereco/detalhe-endereco.module').then(m => m.DetalheEnderecoPageModule)
      },
      {
        path: 'atualizar-perfil',
        loadChildren: () => import('./pages/perfil/atualizar-perfil/atualizar-perfil.module').then(m => m.AtualizarPerfilPageModule)
      },
      {
        path: 'alterar-senha',
        loadChildren: () => import('./pages/perfil/alterar-senha/alterar-senha.module').then(m => m.AlterarSenhaPageModule)
      },
      {
        path: 'perfil-profissional',
        loadChildren: () => import('./pages/profissional/perfil-profissional/perfil-profissional.module').then(m => m.PerfilProfissionalPageModule)
      },
      {
        path: 'detalhe-perfil',
        loadChildren: () => import('./pages/perfil/detalhe-perfil/detalhe-perfil.module').then(m => m.DetalhePerfilPageModule)
      },
      {
        path: 'pergunta/:id',
        loadChildren: () => import('./pages/pergunta/pergunta.module').then(m => m.PerguntaPageModule)
      },
      {
        path: 'cadastrar-area-atuacao-profissional',
        loadChildren: () => import('./pages/areaAtuacaoProfissional/cadastrar-area-atuacao-profissional/cadastrar-area-atuacao-profissional.module').then(m => m.CadastrarAreaAtuacaoProfissionalPageModule)
      },
      {
        path: 'listar-area-atuacao-profissional',
        loadChildren: () => import('./pages/areaAtuacaoProfissional/listar-area-atuacao-profissional/listar-area-atuacao-profissional.module').then(m => m.ListarAreaAtuacaoProfissionalPageModule)
      },
      {
        path: 'detalhe-solicitacao-pedido/:id_SolicitacaoPedido',
        loadChildren: () => import('./pages/solicitacao-pedido/detalhe-solicitacao-pedido/detalhe-solicitacao-pedido.module').then(m => m.DetalheSolicitacaoPedidoPageModule)
      },
      {
        path: 'minha-solicitacao',
        loadChildren: () => import('./pages/solicitacao-pedido/minha-solicitacao/minha-solicitacao.module').then(m => m.MinhaSolicitacaoPageModule)
      },
      {
        path: 'avaliacao/:id_SolicitacaoPedido',
        loadChildren: () => import('./pages/avaliacao/criar-avaliacao/criar-avaliacao.module').then(m => m.CriarAvaliacaoPageModule)
      },
      {
        path: 'editar-documento-profissional/:id',
        loadChildren: () => import('./pages/documentoProfissional/editar-documento-profissional/editar-documento-profissional.module').then(m => m.EditarDocumentoProfissionalPageModule)
      },
      {
        path: 'listar-documento-profissional',
        loadChildren: () => import('./pages/documentoProfissional/listar-documento-profissional/listar-documento-profissional.module').then( m => m.ListarDocumentoProfissionalPageModule)
      },
      {
        path: 'cadastrar-documento-profissional',
        loadChildren: () => import('./pages/documentoProfissional/cadastrar-documento-profissional/cadastrar-documento-profissional.module').then( m => m.CadastrarDocumentoProfissionalPageModule)
      },
      {
        path: 'listar-avaliacao-profissional/:id_profissional/solicitacao/:id_solicitacao',
        loadChildren: () => import('./pages/avaliacao/listar-avaliacao-profissional/listar-avaliacao-profissional.module').then( m => m.ListarAvaliacaoProfissionalPageModule)
      },

      {
        path: 'error',
        component: ErrorComponent
      }
    ]
  // eslint-disable-next-line no-trailing-spaces

  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
