import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnuncioService {

  constructor(private http: HttpClient) { }
  public NomeServico = '/anuncio';
  

  buscarTodas():Observable<any[]>{
    return this.http.get<any[]>(`${environment.urlBase}${this.NomeServico}/BuscarTodos`);
  }

  buscarPorpais(pais: string):Observable<any[]>{
    return this.http.get<any[]>(`${environment.urlBase}${this.NomeServico}/buscarPorPais/${pais}`);
  }

}
