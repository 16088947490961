import { CategoriaModel } from 'src/app/models/categoria.model';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LinguagemService } from 'src/app/services/linguagem.service';
import { TratamentoMensagemApi } from 'src/app/shared/tratamento-mensagem.api';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { TranslateService } from '@ngx-translate/core';
import { LinguagemUltil } from './shared/Ultils/linguagem-ultil';
import { alertController } from '@ionic/core';
import { Router } from '@angular/router';
// import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  showSplash = true;
  selectLinguagem: any;
  linguagens: any[] = [];
  listaCategoria: CategoriaModel[] = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private categoriaService: CategoriaService,
    private linguagemService: LinguagemService,
    // private oneSignalService: OneSignalService,
    private tratamentoMensagemApi: TratamentoMensagemApi,
    // private oneSignal: OneSignal,
    private platform: Platform
  ) {
    platform.ready().then(() => {
      this.OneSignalInit();
    });
  }

  ngOnInit(): void {
    if (LinguagemUltil.buscarLinguagem() == null) {
      this.translate.setDefaultLang(
        LinguagemUltil.retornaLinguagemParaTraducao()
      );
      this.buscarTodasLinguagem();
    }
  }

  OneSignalInit =() => {

    // OneSignal.setAppId("641a8280-b81b-40b1-8a96-b4ade6a3cd29");
    // OneSignal.setNotificationOpenedHandler(function(jsonData){
    //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))

    //   this.oneSignal.promptForPushNotificationsWithUserResponse();
    //   this.oneSignal.inFocusDisplaying(
    //     this.oneSignal.OSInFocusDisplayOption.Notification
    //   );
    //   this.oneSignal.handleNotificationReceived().subscribe((data) => {});

    //   this.oneSignal.handleNotificationOpened().subscribe((data) => {});

    //   this.oneSignal.endInit();
    // });
  }
  // private _configOneSignal(): void {
  //   this.oneSignal.startInit('641a8280-b81b-40b1-8a96-b4ade6a3cd29'); // ONESIGNAL APP ID

  //   this.oneSignal.promptForPushNotificationsWithUserResponse();
  //   this.oneSignal.inFocusDisplaying(
  //     this.oneSignal.OSInFocusDisplayOption.Notification
  //   );
  //   this.oneSignal.handleNotificationReceived().subscribe((data) => {});

  //   this.oneSignal.handleNotificationOpened().subscribe((data) => {});

  //   this.oneSignal.endInit();
  // }

  async alertaSelecionarLinguagem(inputs: any[]) {
    const radioListaLiguagem: any[] = [];
    inputs.forEach((element) => {
      const input = {
        name: element.nome,
        type: 'radio',
        label: element.nome,
        value: element.id,
      };
      radioListaLiguagem.push(input);
    });

    const header = this.translate.instant(
      'ALERTAS.SELECIONAR_LINGUAGEM.HEADER'
    );
    const message = this.translate.instant(
      'ALERTAS.SELECIONAR_LINGUAGEM.MESSAGE'
    );
    const botaoConfirmacao = this.translate.instant('ALERTAS.BOTAO.CONFIRMAR');
    const botaoCancelar = this.translate.instant('ALERTAS.BOTAO.CANCELAR');
    const alert = await alertController.create({
      header: header.toString(),
      message: message.toString(),
      inputs: radioListaLiguagem,
      backdropDismiss: false,

      buttons: [
        {
          text: botaoCancelar.toString(),
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: botaoConfirmacao.toString(),
          id: 'confirm-button',
          handler: (c) => {
            this.setAbreviacao(c);
          },
        },
      ],
    });
    await alert.present();
  }

  setAbreviacao(idLinguagem) {
    const linguagem = this.linguagens.find((x) => x.id === idLinguagem);
    LinguagemUltil.setarLinguagem(linguagem);
    this.translate.setDefaultLang(linguagem.abreviacao);
    window.location.reload();
  }

  buscarTodasLinguagem() {
    const addLinguagemIngles = {
      abreviacao: 'en-en',
      id: '',
      id_Pais: '',
      invalid: false,
      nome: 'English - En',
      notifications: [],
      valid: true,
    };
    this.linguagemService.buscarTodas().subscribe({
      next: (data) => {
        this.linguagens = data;
        if (
          !this.linguagens.find(
            (lingua) => lingua.abreviacao == addLinguagemIngles.abreviacao
          )
        ) {
          this.linguagens.push(addLinguagemIngles);
        }
        this.alertaSelecionarLinguagem(data);
      },
      error: (erro) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(
          erro,
          this.translate
        );
      },
      complete: () => {},
    });
  }

  buscarLinguagemSemLogin(idLinguagem) {
    this.categoriaService.buscarPorLinguagem(idLinguagem).subscribe(
      (categorias) => {
        this.listaCategoria = categorias;
      },
      (erro) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(
          erro,
          this.translate
        );
      }
    );
  }
}
