import { TermoDeUsoModel } from './../../../models/TermoDeUso.model';
import { TermosDeUsoService } from './../../../services/TermosDeUsoService';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LinguagemUltil } from '../../../shared/Ultils/linguagem-ultil';
import { TranslateService } from '@ngx-translate/core';
import { LinguagemService } from '../../../services/linguagem.service';
import { TratamentoMensagemApi } from '../../../shared/tratamento-mensagem.api';

@Component({
  selector: 'app-modal-termos',
  templateUrl: './modal-termos.component.html',
  styleUrls: ['./modal-termos.component.scss'],
})
export class ModalTermosComponent implements OnInit {

  name: string;

  isModalOpen = false;
  Termo:TermoDeUsoModel;
  abreviacao;
  linkTermo;

  labelLink = 'TERMOS_DE_USO.BOTAO_TERMO';

  listaLinkTermos = [
    {
      abreviacao:'pt-pt',
      link: 'https://azcheck.pt/termos-e-condicoes-gerais-de-uso/'
    },
    {
      abreviacao:'fr-fr',
      link: 'https://azcheck.fr/termos-e-condicoes-gerais-de-uso/'
    },
    {
      abreviacao:'es-es',
      link: 'https://azcheck.es/termos-e-condicoes-gerais-de-uso/'
    },
    {
      abreviacao:'pt-br',
      link: 'https://azcheck.net/termos-e-condicoes-gerais-de-uso/'
    },
  ];

  linguagens;
  constructor(private modalCtrl: ModalController,
    private termoDeUsoServico: TermosDeUsoService,
    public translate: TranslateService,
    private linguagemService: LinguagemService,
    private tratamentoMensagemApi: TratamentoMensagemApi    ) {

      this.abreviacao = LinguagemUltil.retornaLinguagemParaTraducao()
      this.translate.setDefaultLang(LinguagemUltil.retornaLinguagemParaTraducao());
      this.buscarTermo(this.abreviacao);
    }


    ngOnInit() {
      this.buscarLinguagens();
    }

    cancel(){
      return this.modalCtrl.dismiss(null, 'cancel');
    }
    setOpen(isOpen: boolean) {
      this.isModalOpen = isOpen;
    }

    buscarTermo(abreviacao){
      switch(abreviacao) { 
        case 'pt-pt': { 
          this.linkTermo = this.listaLinkTermos[0].link 
          break; 
        } 
        case 'fr-fr': { 
          this.linkTermo = this.listaLinkTermos[1].link 
          break; 
        } 
        case 'es-es': { 
          this.linkTermo = this.listaLinkTermos[2].link 
          break; 
       }
        case 'pt-br': { 
           this.linkTermo = this.listaLinkTermos[3].link 
           break; 
        } 
        default: { 
          this.linkTermo = this.listaLinkTermos[3].link 
           break; 
        } 
        
     } 
     LinguagemUltil.setarLinguagem(this.abreviacao);
     this.ngOnInit();
    }

    buscarLinguagens(){
      this.linguagemService.buscarTodas().subscribe(data => {
        this.linguagens = data;
        console.log(this.linguagens)
        if (!LinguagemUltil.buscarLinguagem()) {
          const linguagem = this.linguagens.find(x => x.abreviacao === 'pt-br');
          LinguagemUltil.setarLinguagem(linguagem);
          this.translate.setDefaultLang(LinguagemUltil.retornaLinguagemParaTraducao());
        }
      }, (erro: any) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(erro, this.translate);
      });
    }

    setAbreviacao(abreviacao) {
      this.abreviacao = abreviacao.detail.value;
      this.buscarTermo(this.abreviacao);
   
    }
}
