import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LinguagemUltil } from 'src/app/shared/Ultils/linguagem-ultil';
import { Security } from 'src/app/shared/Ultils/security-ultil';
import { OneSignalTagIdUsuario } from 'src/app/oneSignal/onseSignalTagIdUsuario';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public codigoErro = null;
  public TituloNavBar = '';
  public traducaoIngles = false;

  constructor(
    public translate: TranslateService,
    private oneSignalTagIdUsuario: OneSignalTagIdUsuario,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    if (Security.buscarUsuario()) {
      if (LinguagemUltil.buscarLinguagemIngles()) {
        this.translate.setDefaultLang(
          LinguagemUltil.buscarLinguagemIngles()
        );
        this.traducaoIngles = true;
      }
    }
    Security.setarEstadoUsuario();
    this.route.queryParams.forEach((param) => {
      this.codigoErro = param.NumeroErro;
      this.TituloNavBar = `ERROR.${this.codigoErro}.TITULO`
    });

    this.translate.setDefaultLang(LinguagemUltil.retornaLinguagemParaTraducao());
  }

  irLogin(){
    Security.clear();
    this.oneSignalTagIdUsuario.excluirTags();
    this.router.navigate(['/login']);
  }

}
