import { ModalIdiomasComponent } from './../modal-idiomas/modal-idiomas.component';
import { ModalController } from '@ionic/angular';
import { CategoriaModel } from 'src/app/models/categoria.model';
import { TranslateService } from '@ngx-translate/core';
import { CategoriaService } from 'src/app/services/categoria.service';
import { TratamentoMensagemApi } from 'src/app/shared/tratamento-mensagem.api';
import { LinguagemService } from './../../../services/linguagem.service';
import { Security } from 'src/app/shared/Ultils/security-ultil';
import { LinguagemUltil } from 'src/app/shared/Ultils/linguagem-ultil';
import { Component, Input, OnInit } from '@angular/core';
import { LinguagemAtualizacaoDinamica } from 'src/app/shared/Ultils/linguagem-dinamica';
import { GerenciamentoLinguagem } from 'src/app/shared/Ultils/gerenciar-linguagem';
// import { LinguagemAtualizacaoDinamica } from 'src/app/shared/Ultils/linguagem-dinamica';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() TituloPagina: string;
  @Input() BackBottonOn = false;
  @Input() UrlBack = '/';
  @Input() MenuBottonOn = true;
  @Input() LinguagemBottonOn = false;

  selectLinguagem: any;
  linguagens: any[] = [];
  listaCategoria: CategoriaModel[] = [];
  public checbuttonsz: any[] = [];
  public traducaoIngles = false;
  public usuarioLogado = true;

  public rotas;
  constructor(
    private translate: TranslateService,
    private categoriaService: CategoriaService,
    private tratamentoMensagemApi: TratamentoMensagemApi,
    private linguagemService: LinguagemService,
    private modalCtrl: ModalController,
    private gerenciamentoLinguagem: GerenciamentoLinguagem,
    private linguagemAtualizacaoDinamica: LinguagemAtualizacaoDinamica,
  ) {
    this.translate.setDefaultLang(
      LinguagemUltil.retornaLinguagemParaTraducao()
    );
  }

  ngOnInit() {
    if (Security.buscarUsuario()) {
      if (LinguagemUltil.buscarLinguagemIngles()) {
        this.translate.setDefaultLang(
          LinguagemUltil.buscarLinguagemIngles()
        );
        this.traducaoIngles = true;
      }
    }
    if (this.TituloPagina == 'SOLICITACOES.TITULO') {
      this.LinguagemBottonOn = true;
    }
    this.buscarTodasLinguagem();
    if (Security.buscarUsuario()) {
      this.usuarioLogado = true;

    } else {
      this.usuarioLogado = false;

    }
  }

  buscarTodasLinguagem() {
    this.linguagemService.buscarTodas().subscribe(
      (data) => {
        this.linguagens = data;
      },
      (erro: any) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(
          erro,
          this.translate
        );
      }
    );
  }

  buscarLinguagemSemLogin(idLinguagem) {
    this.categoriaService.buscarPorLinguagem(idLinguagem).subscribe(
      (categorias) => {
        this.listaCategoria = categorias;
      },
      (erro) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(
          erro,
          this.translate
        );
      }
    );
  }

  setarIngles() {
    this.traducaoIngles = true;
    LinguagemUltil.setarTraducaoLinguagemIngles('en-en');
    this.linguagemAtualizacaoDinamica.setarLinguagemDinamica('en-en');
    this.translate.setDefaultLang('en-en');
    this.gerenciamentoLinguagem.linguagemSetada(true)
  }

  setarLinguagemNativa() {
    this.traducaoIngles = false;
    LinguagemUltil.removerTraducaoIngles();
    this.translate.setDefaultLang(LinguagemUltil.retornaLinguagemParaTraducao());
    LinguagemUltil.setarLinguagem(LinguagemUltil.retornaLinguagemParaTraducao());
    this.linguagemAtualizacaoDinamica.setarLinguagemDinamica(LinguagemUltil?.retornaLinguagemParaTraducao());
    this.gerenciamentoLinguagem.linguagemSetada(false)
    this.ngOnInit();
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: ModalIdiomasComponent,
      cssClass: '',
    });
    modal.present();
  }
}
