import { LinguagemUltil } from './../../shared/Ultils/linguagem-ultil';
import { LinguagemService } from './../../services/linguagem.service';
import { LinguagemModel } from './../../models/linguagem.model';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Security } from 'src/app/shared/Ultils/security-ultil';
import { Router } from '@angular/router';
import { TratamentoMensagemApi } from 'src/app/shared/tratamento-mensagem.api';
import { LinguagemAtualizacaoDinamica } from 'src/app/shared/Ultils/linguagem-dinamica';
import { GerenciamentoLinguagem } from 'src/app/shared/Ultils/gerenciar-linguagem';
import { element } from 'protractor';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  linguagens: LinguagemModel[] = [];
  valorInicial: string = LinguagemUltil.buscarLinguagem() ?
    LinguagemUltil.buscarLinguagem().id : "33aaa905-de7a-4904-ad65-0a5e7de29402";
    public traducaoIngles = false;
    valorSelecionado: string; // Propriedade para rastrear o valor selecionado no ion-select


  constructor(
    private router: Router,
    public translate: TranslateService,
    private linguagemService: LinguagemService,
    private tratamentoMensagemApi: TratamentoMensagemApi
  ) {
    this.translate.setDefaultLang(
      LinguagemUltil.retornaLinguagemParaTraducao()
    );
  }

  ngOnInit() {
    const addLinguagemIngles = {
      abreviacao: 'en-en',
      id: '',
      id_Pais: '',
      invalid: false,
      nome: 'English - En',
      notifications: [],
      valid: true,
    };
    this.linguagemService.buscarTodas().subscribe(
      (data) => {
        this.linguagens = data;
        if (
          !this.linguagens.find(
            (lingua) => lingua.abreviacao == addLinguagemIngles.abreviacao
          )
        ) {
          this.linguagens.push(addLinguagemIngles);
        }
        if (!LinguagemUltil.buscarLinguagem()) {
          const linguagem = this.linguagens.find(
            (x) => x.abreviacao === 'pt-br'
          );
          LinguagemUltil.setarLinguagem(linguagem);
          this.translate.setDefaultLang(
            LinguagemUltil.retornaLinguagemParaTraducao()
          );
          // this.linguagemAtualizacaoDinamica.setarLinguagemDinamica(LinguagemUltil.retornaLinguagemParaTraducao());
        }
      },
      (erro: any) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(
          erro,
          this.translate
        );
      }
    );

    if (Security.buscarUsuario() && Security.buscarToken()) {
      this.router.navigate(['/']);
    }
        // Recupere o valor selecionado anteriormente ou defina um valor padrão
        this.valorSelecionado = LinguagemUltil.buscarLinguagem()
        ? LinguagemUltil.buscarLinguagem().id
        : "33aaa905-de7a-4904-ad65-0a5e7de29402";
  }

  login() {
    this.router.navigate(['/login']);
  }

  setAbreviacao(idLinguagemEvent) {
    let idLinguagem = idLinguagemEvent.detail.value;
    let linguagem = this.linguagens.find((x) => x.id === idLinguagem);
    LinguagemUltil.setarLinguagem(linguagem);
    this.translate.setDefaultLang(linguagem.abreviacao);
    // Atualize o valor selecionado toda vez que a seleção for alterada
    this.valorSelecionado = idLinguagem;

    // this.linguagemAtualizacaoDinamica.setarLinguagemDinamica(LinguagemUltil.retornaLinguagemParaTraducao());
  }
}
