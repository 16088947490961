import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})

export class GerenciamentoLinguagem {
    public linguagem = new BehaviorSubject<boolean>(false);
    constructor() { }

    public linguagemSetada(linguagem) {
        this.linguagem.next(linguagem);
    }

    public buscarLinguagemSetadaDinamica(): boolean {
       return this.linguagem.value;
    }

    public buscarLinguagemDinamicaObservable(): Observable<any> {
        return this.linguagem;
     }
}
