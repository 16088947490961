import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnuncioService } from 'src/app/services/anuncio.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { TratamentoMensagemApi } from 'src/app/shared/tratamento-mensagem.api';
import { Security } from 'src/app/shared/Ultils/security-ultil';

@Component({
  selector: 'app-slider-anuncio',
  templateUrl: './slider-anuncio.component.html',
  styleUrls: ['./slider-anuncio.component.scss']
})
export class SliderAnuncioComponent implements OnInit {
  public listaAnuncio = [];

  slideOpts = {
    initialSlide: 1,
    loop: true,
    speed: 400
  };

  constructor(
    private anuncioService: AnuncioService,
    private enderecoServico: EnderecoService,
    private tratamentoMensagemApi: TratamentoMensagemApi,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    Security.setarEstadoUsuario();

    if(Security.buscarUsuario().id != null){
      this.listarAnuncio();
    }

  }

  listarAnuncio() {
    this.enderecoServico.buscarPorUsuario(Security.buscarUsuario().id).subscribe((data) => {
      if (data) {
        this.listaAnuncio = [];
        this.anuncioService.buscarPorpais(data.pais).subscribe((banners) => {
          this.listaAnuncio = banners;

          if(!banners){
            this.anuncioService.buscarTodas().subscribe((data) => {
              this.listaAnuncio = data;
            });
          }
        });


        (erro) => {
          this.tratamentoMensagemApi.mensagemErroRequisicaoApi(erro, this.translate);
        }
      }
    })
  }

  navegarProximaPagina(item:any){
    window.open(`${item.link}`);
  }
}
