import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Security } from '../shared/Ultils/security-ultil';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor( private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if(Security.buscarToken()){
      return true;
    } else if(Security.buscarEntrarSemLogin()){
      return true
    }
    else if(Security.buscarEntrarSemLogin() != null && Security.buscarToken() != null){
        Security.clear();
        this.router.navigate(['/home']);
        return true
      }
    else{
      this.router.navigate(['/home']);
      return true;
    }

  }
}
