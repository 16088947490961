import { ModalController } from '@ionic/angular';
import { LinguagemService } from 'src/app/services/linguagem.service';
import { TratamentoMensagemApi } from 'src/app/shared/tratamento-mensagem.api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LinguagemUltil } from 'src/app/shared/Ultils/linguagem-ultil';
import { Router } from '@angular/router';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-idiomas',
  templateUrl: './modal-idiomas.component.html',
  styleUrls: ['./modal-idiomas.component.scss'],
})
export class ModalIdiomasComponent implements OnInit {
  @Input() isOpen : boolean;

  public selectLinguagem: any;
  public linguagens: any[] = [];

  isLoading = true;
  isBotaoAtivo = false;
  selectedLinguagem = false;
  isModalOpen = false;

  constructor(
    private Router: Router,
    private translate: TranslateService,
    private tratamentoMensagemApi: TratamentoMensagemApi,
    private linguagemService: LinguagemService,
    private modalCtrl: ModalController
  ) {
    this.translate.setDefaultLang(
      LinguagemUltil.retornaLinguagemParaTraducao()
    );
  }

  ngOnInit() {
    this.buscarTodasLinguagem();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  
  buscarTodasLinguagem() {
    this.linguagemService.buscarTodas().subscribe({
      next: (data) => {
        this.linguagens = data;

      },
      error: (erro) => {
        this.tratamentoMensagemApi.mensagemErroRequisicaoApi(erro, this.translate);
      }
    });
  }

  setAbreviacao(idLinguagem) {
    const linguagem = this.linguagens.find((x) => x.id === idLinguagem);
    LinguagemUltil.setarLinguagem(linguagem);
    this.translate.setDefaultLang(linguagem.abreviacao);
    this.cancel();
  }

}
