import { SubCategoriaModel } from '../models/subcategoria.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TermoDeUsoModel } from '../models/TermoDeUso.model';

@Injectable({
  providedIn: 'root'
})
export class TermosDeUsoService {

  constructor(private http: HttpClient) { }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public nomeServico = '/termos';

  buscarPorNome(Nome: string): Observable<TermoDeUsoModel> {
    return this.http.get<TermoDeUsoModel>(`${environment.urlBase}${this.nomeServico}/buscarPorNome/${Nome}`);
  }

  buscarPorLinguagem(Nome: string, abreviacao:string): Observable<TermoDeUsoModel> {
    return this.http.get<TermoDeUsoModel>(`${environment.urlBase}${this.nomeServico}/buscarPorLinguagem/${Nome}/${abreviacao}`);
  }

  buscarPorCategoria(id_Categoria: string): Observable<TermoDeUsoModel[]> {
    return this.http.get<TermoDeUsoModel[]>(`${environment.urlBase}${this.nomeServico}/app/${id_Categoria}`);
  }

  buscarTodos() {
    return this.http.get<TermoDeUsoModel[]>(`${environment.urlBase}${this.nomeServico}`);
  }
}
