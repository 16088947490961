import { BehaviorSubject, Observable } from "rxjs";

export class Security {

    static usuario: BehaviorSubject<any> = new BehaviorSubject<any>({});

    public static setarEstadoUsuario(): void {
        if (Security.buscarUsuario()) {
            let usuario =
            {
                'nome': Security.buscarUsuario().nome,
                'email': Security.buscarUsuario().email,
                'role': Security.buscarUsuario().role
            };

            this.usuario.next(usuario);
        }
    }

    public static buscarEstadoUsuario(): Observable<any> {
        return this.usuario;
    }

    public static setarTipoPerfilCadastro(perfil: any) {
        const data = JSON.stringify(perfil);
        localStorage.setItem('tipoPerfil', btoa(data));
    }

    public static buscarTipoPerfilCadastro() {
        const data = localStorage.getItem('tipoPerfil');
        if (data) {
            return JSON.parse(atob(data));

        } else {
            return null;
        }
    }

    public static removerTipoPerfil() {
        localStorage.removeItem('tipoPerfil');
    }

    public static setarUsuarioCadastro(user: any) {
        const data = JSON.stringify(user);
        localStorage.setItem('usuarioCadastro', btoa(data));
    }

    public static setarUsuario(user: any) {
        const data = JSON.stringify(user);
        localStorage.setItem('usuario', btoa(data));
    }

    public static setarDadosUsuario(user: any, token: string) {
        const data = JSON.stringify(user);
        localStorage.setItem('usuario', btoa(data));
        localStorage.setItem('token', token);
    }

    public static setarToken(token: string) {
        localStorage.setItem('token', token);
    }

    public static buscarUsuarioCadastro(): any {
        const data = localStorage.getItem('usuarioCadastro');
        if (data) {
            return JSON.parse(atob(data));

        } else {
            return null;
        }
    }

    public static buscarUsuario(): any {
        const data = localStorage.getItem('usuario');
        if (data) {
            return JSON.parse(atob(data));

        } else {
            return null;
        }
    }

    public static buscarToken(): string {
        const data = localStorage.getItem('token');
        if (data) {
            return data;
        } else {
            return null;
        }
    }

    public static hasToken(): boolean {
        if (this.buscarToken()) {
            return true;
        }
        return false;
    }

    public static entrarSemLogin(data: any) {
        const response = JSON.stringify(data);
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('entrarSemLogin', btoa(response));
    }

    public static buscarEntrarSemLogin() {
        const data = localStorage.getItem('entrarSemLogin');
        if (data) {
            return JSON.parse(atob(data));
        } else {
            return null;
        }
    }

    public static removerEntrarSemLogin() {
        localStorage.removeItem('entrarSemLogin');
    }

    public static clear() {
        localStorage.removeItem('usuario');
        localStorage.removeItem('token');
        localStorage.removeItem('entrarSemLogin');
        localStorage.removeItem('usuarioCadastro');

        this.usuario = new BehaviorSubject<any>({});
    }
}
