import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LinguagemAtualizacaoDinamica {
    public linguagem = new BehaviorSubject('');
    constructor() { }

    public setarLinguagemDinamica(linguagem) {
        this.linguagem.next(linguagem);
    }

    public RetornarlinguagemSetadaDinamica(): string {
        return this.linguagem.value;
    }
}
